
body {
  background-color: black;
  font-size: medium;
}


.arguments-app {
  .side-bar .nav {
    display: block;

    .nav-link {
      color: rgb(255, 255, 255);
      font-weight: 600;

    }
  }

  .navbar {
    height: 6em;
    background-color: #046271 !important
  }

  .alert {
    background-color: #f8dfb8;
    border-radius: 1em;
    color: #060226;
    font-size: medium;
    font-weight: 400;
    margin: 20px 2px 2px;

    a {
      color: #050e5a;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .navbar-dark {
    .navbar-nav .nav-link {
      color: rgb(253, 253, 255);
      font-weight: 600;
      margin-right: 1em;
    }

    .navbar-nav.show>.nav-link {
      color: rgb(248, 251, 251);
    }

    @media (min-width: 1199px) {
      .dropdown-menu.show {
        background-color: #25414f;
        padding-left: 2em;
      }
    }


    @media (max-width: 1199px) {
      .navbar-collapse.collapse {
        background-color: #25414f;
        padding-left: 2em;

        .dropdown-menu.show {
          background-color: #25414f;
          padding-left: 2em;
        }
      }
    }

    .navbar-brand {
      font-size: 2.2em;
      font-weight: 700;
      color: rgb(255 255 255);
      margin-left: 1em;
    }
  }



  .context-container,
  .page-context {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .context-col {
    background-color: rgb(48, 81, 97);
    border-radius: 2em;
    padding: 1em;

    @media (max-width: 767px) {
      .articles-list,  .arguments-list {
        flex-direction: column !important;
        .amplify-rating {
          font-size: xx-small
         }
      }

      .fs {
        flex: 0 0 50px;
        width: 100px;
      }
    }

  }

  .side-bar-col {
    background-color: #063f49;
    margin-left: 1em;
  }

  .app-body {
    background: #96adb6;
    color: white;
  }

  .auth-button {
    padding: 2px;
    width: 90px;
    border-radius: 2em;
    position: absolute;
  }

  .App .amplify-button {
    margin: 1em;
    float: right;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  .articles {
    background-color: #6a97eb;
    padding: 1em;
    color: black;
  }

  .args-close{
    display: none; 
  }
  .accordion-articles {
    background-color:rgb(255, 255, 255);
 

    .amplify-accordion__item__trigger .amplify-text {
      font-size: x-large;
      display: list-item;          /* This has to be "list-item"                                               */
      list-style-type: square;     /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
      list-style-position: inside;
    }
  }

.topic .amplify-input {
    border-radius: 0;
    background-color: #f8f8f8;
    color: #000000;
  }

.topic {
  .chat-text .amplify-textarea {
    border-radius: 0;
    background-color: #000000;
    color: #ffff;
  }
}  
  .amplify-accordion__item__body {
    color: var(--amplify-colors-white);
  }

  .articles-list,  .arguments-list, .ai-arguments 
    {
    padding: 1em;
    background-color: white;
    padding: 1em;
    margin: 1em;
    background-color: white;
    border: 2px solid rgb(173 177 205);

    .wrapImage {
      float: right; 
      margin: 1em;
      width: 120px;;
     }


    .f1 {
      flex: 1 0 120px;
    }

    .f2,
    .f3,
    .f4,
    .f5 {
      flex: 1 0 300px;
    }

    .fs {
      flex: 0 0 100px;
      width: 150px;
    }
  }


  .article-publish {
    background-color: #c9e3f6;
    padding: 1em;
    color: black;
    font-weight: 400
  }


  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .nav-link.selected {
    background-color: rgb(15, 46, 130);
    border: 1px solid aliceblue;
    text-align: center;
  }

  .brand {
    background-color: #063f49;
    padding: 0 1em;
    text-align: center;
  }

  .brand.selected {
    border: 1px solid aliceblue;
  }

}



#google_translate_element {
  width: 300px;
  height: 2em;
  position: fixed;
  z-index: 100000;
  top: 40px;
  left: 40%;
}